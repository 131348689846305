<template>
	<div class="google-map-loader component--wrapper" :ref="componentRef">
		<template v-if="isMapDetected">
			<slot :google="google" :map="map" />
		</template>
	</div>
</template>

<script>
// import { Loader } from "@googlemaps/js-api-loader";
import { makeID } from '@/shared/utils';

let loadModulePromise;
const loadModule = (options) => {
	if (Object.prototype.hasOwnProperty.call(window, 'google')) {
		return Promise.resolve();
	}
	const opt = { libraries: 'places', ...options };
	const parameters = Object.keys(opt)
		.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(opt[key])}`)
		.join('&');
	let url = `https://maps.googleapis.com/maps/api/js?${parameters}`;
	return loadJS(url).catch((e) => {
		loadModulePromise = null;
		console.warn('Error loading google maps script', e);
	});
};

export default {
	name: 'GoogleMapLoaderComponent',
	props: {
		mapConfig: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	data() {
		return {
			google: null,
			map: null
		};
	},
	computed: {
		/**
		 * @return {String}
		 */
		componentRef() {
			return `googleMap${makeID(10)}`;
		},

		/**
		 * @return {Boolean}
		 */
		isMapDetected() {
			return Boolean(this.google) && Boolean(this.map);
		}
	},
	created() {
		const apiKey = process.env.VUE_APP_GOOLGE_API_KEY;
		// STUB for vue2-google-maps and vue-google-places work together
		// TODO chanhe this to @google/map module in future
		if (typeof this.$gmapApiPromiseLazy === 'function') {
			loadModulePromise = this.$gmapApiPromiseLazy();
		} else {
			loadModulePromise =
				loadModulePromise ||
				loadModule({
					key: apiKey,
					v: this.version,
					version: 'weekly',
					libraries: ['places']
				});
		}

		// const loader = new Loader({
		//   apiKey: process.env.VUE_APP_GOOLGE_API_KEY,
		//   version: "weekly",
		//   libraries: ["places"],
		// });
		// loader.load().then(() => {
		//   this.initMap();
		//   // this.initInfoWindow();
		//   // this.initMarkers();
		// });
	},
	mounted() {
		loadModulePromise.then(() => this.initMap());
	},
	// mounted() {
	//   const apiKey = process.env.VUE_APP_GOOLGE_API_KEY;
	//   // const googleMapApi = new GoogleMapsApiLoader({
	//   //   apiKey,
	//   //   version: "weekly",
	//   //   libraries: ["places"],
	//   // });
	//   // this.google = googleMapApi;
	//   // this.initializeMap();

	//   const loader = new Loader({
	//     apiKey,
	//     version: "weekly",
	//     libraries: ["places"],
	//   });

	//   const mapContainer = this.$refs[this.componentRef];

	//   loader
	//     .load()
	//     .then((google) => {
	//       this.google = google;
	//       return (this.map = new google.maps.Map(mapContainer, this.mapConfig));
	//     })
	//     .catch((e) => console.error({ e }));
	// },
	// methods: {
	//   initializeMap() {

	//     console.log({ 'this.google': this.google })
	//     this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
	//   },
	// },

	methods: {
		initMap() {
			const options = Object.assign(
				{},
				{
					center: { lat: -21.53020655039973, lng: 163.461246591539 },
					zoom: 3,
					streetViewControl: false,
					mapTypeControl: false,
					scrollwheel: false
				},
				this.mapConfig
			);
			this.google = google;
			this.map = new google.maps.Map(this.$refs[this.componentRef], options);
		}
	}
};
</script>

<style>
.google-map-loader.component--wrapper {
	height: 300px;
}
</style>
